@import './default';
// *****IMPORTANT NOTE *****
// This file controls all the website's styling; please only make updates if you're confident in your ability to do so.
// ******************************

$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
  3xl: 2048px,
);

// Primary colors
$primary: #3d6b56;
$primary-100: #e9ecf0;
$primary-200: #d8dce3;
$primary-300: #bbc1c9;
$primary-400: #858f9d;

// Secondary colors
$secondary: #3d6b56;
$secondary-100: #e5f7ee;
$secondary-200: #a6d4bf;
$secondary-300: #5c947b;
$secondary-400: #2d4e3f;

// Grayscale
$white: #ffffff;
$gray-25: #e9ecf0;
$gray-50: #f2f4f7;
$gray-100: #e9ecf0;
$gray-200: #d8dce3;
$gray-300: #bbc1c9;
$gray-400: #858f9d;
$gray-500: #576170;
$gray-600: #37404e;
$gray-700: #1b2129;
$gray-dark: #0c2329;
$black: #0c2329;

// Background
$background-theme: #ffffff;
$background-theme-dark: #ffffff;
$background-page: #ffffff;

// Text color base
$text-color-default: #2a384e;

// Color text default and Heading (dark-theme)
$heading-color: #3d6b56;
$body-color-text: #2a384e;

//Placeholder
$placeholder-bg: $gray-50;
$placeholder-bg-100: #ececec;
$placeholder-bg-200: #ebf1f1;
$placeholder-bg-300: #e3eff1;

$placeholder-bg-dark: rgba(43, 44, 44, 0.3);
$placeholder-bg-dark-100: rgba(76, 75, 75, 0.3);
$placeholder-bg-dark-200: rgba(33, 41, 41, 0.4);
$placeholder-bg-dark-300: rgba(43, 59, 61, 0.3);

// Border
$border-radius: 12px;
$border-radius-md: 8px;
$border-radius-sm: 2px;
$border-radius-full: 9999px;
$border-radius-pill: 16px;

// Spacing
$space-x: 16px;
$space-y: 11px;

// Transition
$transition-all: all 0.25s ease-in;

// Opacity
$opacity: 0.8;

// Overlay Color
$overlay-bg: rgba(18, 37, 33, 0.3);

// Shadow
$box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 8px;
$box-shadow-2: 0px 0px 12px rgba(186, 186, 186, 0.3);
$box-shadow-3: 0px -6px 8px -6px rgb(186 186 186 / 30%);

// Font Size
$text-small: 0.55rem;
$text-xs: 0.75rem;
$text-sm: 0.875rem;
$text-base: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-2xl: 1.5rem;
$text-3xl: 1.875rem;
$text-4xl: 2.25rem;
$text-5xl: 3rem;
$text-6xl: 3.75rem;
$text-7xl: 4.5rem;
$text-8xl: 6rem;
$text-9xl: 8rem;

// Base Component

// Button
$border-radius-btn: 16px;
//primary
$btn-primary: #3d6b56;
$label-btn-primary: $white;
$border-btn-primary: #3d6b56;
$btn-primary-hover: #3d6b56;
$label-btn-primary-hover: $white;
$border-btn-primary-hover: #3d6b56;
$btn-primary-pressed: #3d6b56;
$label-btn-primary-pressed: $white;
$border-btn-primary-pressed: #3d6b56;
$btn-primary-disabled: #bbc1c9;
$label-btn-primary-disabled: #ffffff;
$border-btn-primary-disabled: #bbc1c9;

//secondary
$btn-secondary: #162b24;
$label-btn-secondary: #ffffff;
$background-btn-secondary: #162b24;
$btn-secondary-hover: #162b24;
$label-btn-secondary-hover: #ffffff;
$background-btn-secondary-hover: #162b24;
$btn-secondary-pressed: #162b24;
$label-btn-secondary-pressed: #ffffff;
$background-btn-secondary-pressed: #162b24;
$btn-secondary-disabled: #e9ecf0;
$label-btn-secondary-disabled: #bbc1c9;
$background-btn-secondary-disabled: #f7f8fa;

//link
$label-btn-link: #3d6b56;
$label-btn-link-hove: #3d6b56;

// Swiper Pagination Bullet
$swiper-bullet: $gray-400;
$swiper-bullet-active: $primary;

//Close
$btn-close-font-size: 20px;
$btn-close-padding: 4px;
$btn-close-border-radius: 0;
$btn-close-icon-color: #576170;
$btn-close-background: inherit;
$btn-close-icon-color-hover: #0c1014;
$btn-close-background-hover: inherit;
$btn-close-border-color: transparent;
$btn-close-border-color-hover: transparent;

// Input
$border-radius-input: 16px;
$input-bg: #fafbfc;
$input-disabled-bg: #f7f8fa;
$input-color: #0c2329;
$input-border-color: #e9ecf0;
$input-border-color-hover: #d8dce3;
$input-border-color-activate: #3d6b56;
$input-color-label: #576170;
$input-color-label-activate: #3d6b56;
$input-ckek-border-color: #bbc1c9;
$input-ckek-background-color: #fafbfc;
$input-ckek-background-select: #3d6b56;
$input-color-label-ckeck: $gray-600;
$input-check-color: #3d6b56;
$input-check-select: $white;
$input-invalid-color: #cc321b;
$input-icon-color: 030405;
$input-icon-color-activate: 6da738;
$input-check-radio-button: 6da738;

// Badge
$border-radius-badge: 12px;
$badge: #3d6b56;
$badge-counter: #39747c;
$badge-specials: #0d0d0d;
$badge-text: #ffffff;
$badge-warning: #ffbb11;
$badge-text-warning: #664b07;

//Badge BOGO
$bagde-bogo: #ffffff;
$badge-bogo-text: #be6d00;
$badge-bogo-border: 1px solid#F8CF99;

// Text Colors
$errors: #cc321b;
$success: #97cc69;
$hover: #3d6b56;
$alert-message: #e63541;

//Select
$select-menu-bg: $white;
$select-menu-border-radius: 4px;
$select-menu-item-bg-hover: $gray-50;
$select-menu-item-bg-select: $gray-25;
$select-menu-item-color-hover: $black;
$select-menu-item-color-select: $black;
$select-menu-item-shadow: 0px 0px 12px rgb(186 186 186 / 30%);

// Alert
$login-success-border-color: #0c2329;
$login-success-color: #0c2329;
$login-text-success-color: #ffffff;

$alert-errors-border-color: #f8d3cf;
$alert-errors-color: #f8d3cf;
$alert-text-errors-color: #85140a;
$alert-errors-icons-color: #85140a;

$alert-success-border-color: #a7e4bb;
$alert-success-color: #a7e4bb;
$alert-text-success-color: #0e4b22;
$alert-success-icons-color: #0e4b22;

$alert-warning-border-color: #fff1cf;
$alert-warning-color: #fff1cf;
$alert-text-warning-color: #99700a;
$alert-warning-icons-color: #99700a;

$alert-info-color: #d3e0f5;
$alert-info-border-color: #d3e0f5;
$alert-text-info-color: #0e2952;
$alert-info-icons-color: #0e2952;

$alert-border-radius: 8px;

//Express Shipping Banner
$express-banner-bg-color: $primary;
$express-banner-text-color: #0d0d0d;
$express-banner-bg-color-express-mode: #c2ddad;
$express-banner-text-color-express-mode: #294414;
$express-banner-transition-duration: 0.5s;
$express-banner-border-radius: 10px;
$express-banner-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

//Styles of the Main Components and Pages

// Age
$age-logo-max-width: 300px;
$age-logo-max-height: auto;
$sge-btn-primary-border-radius: 4px;
$age-title: #122430;
$age-message: #37727a;
$age-message-hover: #37727a;
$age-link: #3d6b56;
$age-background-color: #ffffff;

// CRM Modal
$crm-header-color: #0c2329;
$crm-background-color: #ffffff;

// Not-found 404
$not-found-page: #e0ecd0b8;

// Promotions
$background-promotion: #3d6b56;
$color-text-promotion: #ffffff;
$text-transform-promotions: uppercase;
$efect-promotion: #faf9f8;
$progress-color: #3d6b56;

// Header
$header-logo-max-width: 280px;
$background-header: #ffffff;
$header-background-image: none;
$backdrop-filter: unset;
$logo-header-spacing-left: 0px;
$logo-headder-min-width: 209px;
$header-action-icon-color: #0c2329;
$header-divider: #e9ecf0;
$header-input-placeholder-color: #cccccc;
$header-input-border-color: #cccccc;
$header-input-border-color-activate: #cccccc;
$header-input-background: #fafafa;
$header-input-border-radius: 12px;
$header-input-text-color: #0c2329;
$header-button-search-color: #3d6b56;
$header-button-search-hover-color: #3d6b56;
$header-button-icon-color: #ffffff;
$header-button-icon-hover-color: #ffffff;
$header-input-clear-color: 1b2129;
$header-input-separator-color: $header-input-border-color;

// MedRecSwitch
$med-rec-switch-background: #fafafa;
$med-rec-switch-border-radius: 12px;
$med-rec-switch-border-color: #cccccc;
$med-rec-switch-border-color-hover: #cccccc;
$label-btn-mode-rec: #ffffff;
$background-btn-mode-rec: #3d6b56;
$background-btn-mode-rec-hover: #3d6b56;
$label-btn-mode-med: #b3b3b3;

// Store-Selector
$background-store-selector: #ffffff;
$selector-btn-background: #ffffff;
$selector-btn-text-color: #3d6b56;
$selector-btn-address-color: #0c2329;
$selector-icon-color: #0c2329;
$selector-heading-color: #0c2329;
$selector-mode-border-color: #fafafa;
$selector-mode-border-color-hover: #fafafa;
$selector-mode-bg: #fafafa;
$selector-mode-border-radius: $border-radius-md;
$label-btn-mode: #ffffff;
$background-btn-mode: #0c2329;
$border-color-btn-mode: #0c2329;
$label-btn-mode-secondary: #b3b3b3;
$background-btn-mode-active: $background-btn-mode;
$border-color-btn-mode-active: $border-color-btn-mode;
$label-btn-mode-secondary-active: $label-btn-mode-secondary;
$selector-text-address-item: #576170;
$selector-current-location-color: #0c2329;

// Store-Validator
$card-validator-title-color: #0c2329;
$card-validator-background: #ffffff;
$card-validator-alert-border-radius: 8px;
$cart-validator-divider: #f2f4f7;
$cart-validator-text-secundary: #656565;

// GR-Limit
$limit-value: #77879d;
$limit-current-value: #0c2329;
$limit-border-color: #e9ecf0;

// Side Navigation
$background-side-nav: $white;
$side-nav-backdrop-filter: unset;
$background-header-side-nav: #899f79;
$side-nav-logo-max-width: 300px;
$side-nav-border-header: 5px solid $primary;
$side-nav-button-location-color: $secondary-400;
$side-nav-icon-button-location-color: $secondary-400;
$side-nav-border-main: 5px solid $primary;
$side-nav-color: $secondary-400;
$side-nav-item-color: $gray-600;
$side-nav-item-icon-color: $label-btn-link;
$side-nav-item-weigth-icon-color: initial;
$side-nav-subitem-color: $secondary-400;
$side-nav-subitem-bg: #c8cac7;
$side-nav-active-color: $primary;
$side-nav-active-bg: #c8cac7;

// Menu
$tab-btn: #3d6b56;
$nav-text-color: #0c2329;
$nav-icon-color: $label-btn-link;
$background-nav: $background-header;
$background-nav-image: $header-background-image;
$backdrop-filter-nav: unset;

$tab-btn-general: $tab-btn;

// Mega Menu
$background-megamenu: #ffffff;
$megamenu-backdrop-filter: unset;
$mega-title-color: #0c2329;
$mega-text-color: #0c2329;
$mega-description-color: #576170;
$mega-border-color: #3d6b56;
$mega-divider: #e9ecf0;
$mega-link-hover: $tab-btn;
$mega-single-border-radius: $alert-border-radius;

// Search Header
$search-header-background: #ffffff;
$search-header-backdrop-filter: unset;
$search-title-color: #0c2329;
$search-border-color: #e9ecf0;
$search-see-all-link-color: #0c2329;
$background-product-card-header: #ffffff;
$search-icon-color: 78879d;
$search-icon-color-focus: 78879d;

// Cart Navigation
$background-shoping-cart: #ffffff;
$shoping-cart-backdrop-filter: unset;
$cart-title-color: #0c2329;
$cart-btn-close: #576170;
$cart-method-select: #576170;
$cart-market: #0c2329;
$cart-adderss: #0c2329;
$cart-divider: #f2f4f7;
$cart-empty-icon: #3d6b56;
$cart-empty-text: #576170;
$cart-footer-text: #576170;
$cart-help-text: #576170;
$cart-footer-price: #0c2329;
$cart-body-bacground-image: "#ffffff";
$cart-body-border-radius-image: $border-radius-md;
$cart-body-text-color: #0c2329;
$cart-body-text-color-secondary: #576170;
$cart-bundle-text-color: $primary;
$cart-footer-background: #fafafa;
$cart-footer-divider: $cart-divider;
$cart-badge-on-sale-text-color: $white;
$cart-badge-specials-text-color: $white;
$cart-active-bogo-color: #a8c9b6;
$cart-active-bogo-color-text: #a8c9b6;

// Hero
$background-hero: #f7f7f7;
$border-radius-image-hero: 8px;

// Filter Treez
$background-filters-treez-modal: #ffffff;
$background-filters-treez-desktop: #ffffff;
$filter-title-section: #0c2329;
$filter-algolia-title: #050606;
$filter-header-close-icon: $secondary-400;
$filter-algolia-title-counter: #576170;
$filter-algolia-bage: #37404e;
$filter-icon-color: #050606;
$filter-control-slider-border-color: $secondary-400;
$filter-control-slider-bg-color: $secondary-300;
$filter-track-slider-border-color: $secondary-400;
$filter-track-slider-bg-color: $secondary-300;
$filter-algolia-divider: #e9ecf0;
$filter-start-active-color: #a8c9b6;
$filter-pill-background: #a8c9b6;
$filter-pill-text-color: #375321;
$filter-pill-border-color: #a8c9b6;
$filter-pill-border-type: solid;
$filter-background-input: #fafbfc;
$filter-background-btn-filters: #f7f7f7;
$filter-border-color-btn-filters: #bbc1c9;
$filter-text-color-btn-filters: #2a384e;
$background-color-collection: #f2f4f7;
$background-placeholder-product: #ebf0f7;

// Controls Slider
$see-all-btn: #ffffff;
$text-see-all-btn: #0c2329;
$see-all-link: #070c15;
$see-all-counter: #a9b6c9;
$controls-slider: #070c15;
$controls-border: #f7f7f7;
$controls-disabled: #ccd5e3;

// Product
$background-product-card: #ffffff;
$border-radius-card: 10px;
$discount-price: #78879d;
$discount-price-dark: #78879d;
$btn-actions-product: #0c2329;
$btn-actions-product-dark: #0c2329;
$link-inf-product: #58724f;
$product-price: #0c2329;
$product-price-dark: #0c2329;
$product-brand: #787d87;
$product-divider: #e9ecf0;
$product-divider-default: #e9ecf0;
$product-tch-cbd: #a9b6c9;
$product-placeholder-color: #ebf0f7;
$product-placeholder-image-color: #f2f4f7;
$product-tax-applied-message: #5a6470;
$box-shadow-prodct-hover: 25px 0 20px -20px rgba(0, 0, 0, 0.08),
  -25px 0 20px -20px rgba(0, 0, 0, 0.08);

//bundle popup in card product
$background-bundle-popup: #8dc457;
$text-color-bundle-popup: $white;
$action-close-color: #eef9e0;
$bundle-title-color: #0c2329;
$bundle-popup-item: #0c2329;
$bundle-popup-icon: #8dc457;

// Counter
$counter-border: #b3b3b3;
$counter-divider: #b3b3b3;
$counter-operation-color: #0d0d0d;
$counter-operation-color-hover: #8dc457;
$counter-text: #0d0d0d;
$counter-border-radius: 8px;
$counter-bacground: #fafafa;
$counter-bacground-count: #f5f5f5;

// Bundle Specials
$bundle-specials-background: #121821;
$bundle-specials-title-color: #ffffff;
$bundle-spacials-descriptions: #b3bcc9;
$bundle-specials-border-default: #5a6573;
$bundle-specials-border-active: #3d6b56;
$bundle-special-icon-default: #b3bcc9;
$bundle-special-icon-active: #ffffff;
$special-placeholder-color: rgb(239, 242, 241);

//Specials Carousel
$specials-card-border-radius: 16px;

// Rating
$rating-color: $black;

// Rating & Review
$rating-review-color: #070c15;
$rating-review-bar-color: $primary;
$states-btn-border-color: #0c2329;
$states-btn-text-color: #0c2329;
$states-btn-rating-color: #0c2329;
$states-btn-bg-color: #ffffff;
$states-btn-active-border-color: #54862a;
$states-btn-active-text-color: #375321;
$states-btn-active-bg-color: #a8c9b6;
$states-btn-hover-border-color: #54862a;
$states-btn-hover-text-color: #375321;
$states-btn-hover-bg-color: #a8c9b6;

// Footer
$footer-logo-max-width: 300px;
$footer-logo-max-height: 64px;
$background-footer: #3d6b56;
$footer-background-image: none;
$border-top: 1px solid $background-footer;
$color-text-footer: $white;
$text-form-subscribe: $white;
$btn-background-footer: #ffffff;
$btn-background-footer-hover: #ffffff;
$text-btn-footer: #3d6b56;
$text-btn-footer-hover: #3d6b56;
$input-border-color-footer: $white;
$input-border-color-footer-hover: $white;
$input-border-color-footer-activate: $white;
$input-placeholder-color: #b3bcc9;
$input-placeholder-color-activate: $white;
$input-text-color: $white;
$input-background-footer: transparent;
$footer-link-text-color: #ffffff;
$footer-link-text-color-hover: #ffffff;

// Pages
// Account Page
$account-photo-username: #2e3061;
$account-photo-direction: #b6c4c9;
$account-price-discount: #e63e3e;
$account-btn-link: #0c2329;
$account-btn-link-hover: $primary;
$account-message-border-color: #bbc1c9;
$account-message-heading-color: #0c2329;
$account-message-text-color: #2a384e;
$account-tap-color: #0c2329;

// Product Detail Page
$background-detail: #ffffff;
$detail-divider: #e9ecf0;
$detail-divider-default: #e9ecf0;
$detail-sharing-icon: #58724f;
$detail-link-category: #58724f;
$detail-sharing-border-color: #ccd5e3;
$detail-border-rating-review: #e9ecf0;
$detail-counter-border: #ccd5e3;
$detail-counter-operation: #1b2129;
$detail-counter-value-dark: #1b2129;
$detail-link-change-location: #58724f;
$detail-placeholder-color: #ebf0f7;
$detail-background-img: #f7f7f7;
$detail-counter-btn: #3d6b56;
$detail-counter-label-btn: $white;
$detail-counter-border-btn: #3d6b56;
$detail-counter-btn-hover: #1b2129;
$detail-counter-label-btn-hover: $white;
$detail-counter-border-btn-hover: #1b2129;
$detail-counter-btn-pressed: #0c1014;
$detail-counter-label-btn-pressed: $white;
$detail-counter-border-btn-pressed: #0c1014;
$detail-warning-btn: #2a384e;
$detail-warning-text: #576170;
$detail-stock-border-color: #58724f;
$detail-stock-bg-color: #a8c9b6;
$detail-stock-text-color: #375321;
$detail-background-product-info: $white;
$detail-border-radius-product-info: 16px;
$detail-props-badge-bg: transparent;
$detail-props-badge-text-color: #656565;
$detail-props-badge-border-color: #656565;
$detail-icon-props: #070c15;
$detail-label-props: $secondary-400;

//Active Bogo
$active-bogo: #a8c9b6;
$active-bogo-text: #0e4b22;
$active-bogo-border: none;

//weight-variant
$detail-weight-variant-btn-border-type: dashed;
$detail-weight-variant-btn-border: #a6acb4;
$detail-weight-variant-btn-text-color: #222222;
$detail-weight-variant-btn-bg-color: transparent;
$detail-weight-variant-btn-active-border: #222222;
$detail-weight-variant-btn-active-text-color: #222222;
$detail-weight-variant-btn-active-bg-color: #a8c9b6;
$detail-weight-variant-btn-hover-border: #a6acb4;
$detail-weight-variant-btn-hover-text-color: #222222;
$detail-weight-variant-btn-hover-bg-color: #a8c9b6;

//Blogs
$image-border-radius: 16px;

$related-post-background: inherit;
$related-post-title-color: initial;
$related-post-time-color: initial;
$related-post-marging-bottom: 40px;

$blog-filter-bage: $secondary;
$blog-filter-bage-color: #ffffff;

$blog-header-input-placeholder-color: #cccccc;
$blog-header-input-border-color: #cccccc;
$blog-header-input-border-color-activate: #cccccc;
$blog-header-input-background: #fafafa;
$blog-header-input-border-radius: 12px;
$blog-header-input-text-color: #0c2329;
$blog-header-button-search-color: $primary;
$blog-header-button-search-hover-color: $primary;
$blog-header-button-icon-color: #ffffff;
$blog-header-button-icon-hover-color: #ffffff;
$blog-header-input-clear-color: 1b2129;
$blog-header-input-separator-color: $header-input-border-color;

//Store Locations
$store-locator-background: #ffffff;
$store-locator-background-modal: #fafafa;

$store-locator-placeholder-map-background: rgba(0, 0, 0, 0.6);

$store-status-border-radius: 4px;
$store-status-closed-border-color: #f8d3cf;
$store-status-closed-color: #f8d3cf;
$store-status-closed-text-color: #85140a;

$store-status-open-border-color: #a7e4bb;
$store-status-open-color: #a7e4bb;
$store-status-open-text-color: #0e4b22;

$store-locator-info-border-radius: 12px;
$store-locator-info-background: #fcfcfc;
$store-locator-info-title: #0d0d0d;
$store-locator-info-text: #656565;

$store-locator-input-placeholder-color: #656565;
$store-locator-input-border-color: #f0f0f0;
$store-locator-input-border-color-activate: #f0f0f0;
$store-locator-input-background: #f0f0f0;
$store-locator-input-border-radius: 12px;
$store-locator-input-text-color: #0c2329;
$store-locator-button-search-color: $primary;
$store-locator-button-search-hover-color: $primary;
$store-locator-button-icon-color: #ffffff;
$store-locator-button-icon-hover-color: #ffffff;
$store-locator-input-clear-color: 1b2129;
$store-locator-input-separator-color: $header-input-border-color;

//Order-Detail Page
$order-status-color: $btn-primary;
$order-status-bg-color: transparent;
$order-status-border-color: $btn-primary;
$order-status-active-color: $label-btn-primary;
$order-status-bg-active-color: $btn-primary;
$order-status-border-active-color: $btn-primary;

//Thank You Page
$thanku-banner-text: #4b5e66;
$thanku-banner-order-number: #eff4f5;
$thanku-order-detail-title: #212121;
$thanku-thead: #6d8992;
$thanku-tbody: #062028;
$thanku-quantity: #4b5e66;

// Utils Product flower types
$flower-type-default-bg-color: #f7d395;
$flower-type-default-color: #db9317;
$flower-type-sativa-bg-color: #ffdfc2;
$flower-type-sativa-color: #7b522c;
$flower-type-indica-bg-color: #ffbdce;
$flower-type-indica-color: #72273a;
$flower-type-hybrid-bg-color: #f5d4f6;
$flower-type-hybrid-color: #7d3d7d;
$flower-type-vape-bg-color: #43cdf0;
$flower-type-vape-color: #007795;
$flower-type-extract-bg-color: #42c074;
$flower-type-extract-color: #198f48;
$flower-type-cbd-bg-color: #fcd1d1;
$flower-type-cbd-color: #c62221;
$flower-type-si-bg-color: #fce7d3;
$flower-type-si-color: #000000;
$flower-type-is-bg-color: #fdeff2;
$flower-type-is-color: #030303;

//Padding Section
$padding-section: 18px;
$padding-section-mobile: 12px;

$logo-text-padding-bottom: 40px;

//Margin Section
$margin-section: 18px;
$margin-section-mobile: 12px;

//Padding Slice
$padding-slice-mobile-small: 0;
$padding-slice-desktop-small: 0;

$padding-slice-mobile-medium: 12px;
$padding-slice-desktop-medium: 18px;

$padding-slice-mobile-large: 24px;
$padding-slice-desktop-large: 36px;
